import {
  APPLICATIONSPAGE_CMS_DATA,
  SHOW_LOADER,
} from './ApplicationsPageActionTypes';

const initialState = {
  applicationsPageCMSData: [],
  isLoader: false,
};

export const applicationsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATIONSPAGE_CMS_DATA:
      return {
        ...state,
        applicationsPageCMSData: action.payload,
      };
    case SHOW_LOADER:
      return {
        ...state,
        isLoader: action.isLoader,
      };
    default:
      return state;
  }
};
