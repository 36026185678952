import {
  HOMEPAGE_CMS_DATA,
  HOMEPAGE_GET_DATA,
  HOMEPAGE_NEWS_DATA,
  OTEHR_WEIR_APPS_CMS_DATA,
  OTHER_WEIR_APPS,
  SHOW_LOADER,
} from './HomePageActionTypes';

const initialState = {
  homePageData: [],
  homePageCMSData: [],
  HOMEPAGE_NEWS_DATA: [],
  otherApps: [],
  otherAppsCMSData: [],
  isLoader: false,
};

export const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOMEPAGE_GET_DATA:
      return {
        ...state,
        homePageData: action.payload,
      };
    case HOMEPAGE_CMS_DATA:
      return {
        ...state,
        homePageCMSData: action.payload,
      };
    case HOMEPAGE_NEWS_DATA:
      return {
        ...state,
        homePageNewsData: action.payload,
      };
    case OTHER_WEIR_APPS:
      return {
        ...state,
        otherApps: action.payload,
      };
    case OTEHR_WEIR_APPS_CMS_DATA:
      return {
        ...state,
        otherAppsCMSData: action.payload,
      };
    case SHOW_LOADER:
      return {
        ...state,
        isLoader: action.isLoader,
      };
    default:
      return state;
  }
};
