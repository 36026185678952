export const applicationRouteConstants = {
  // DEFAULT: `${applicationName}`,
  LOGIN: `/login`,
  SiteInformation: `/site-information`,
  SIGNUP: `/register`,
  SELECTION_PAGE: '/selection-page',
  HOME: '/home',
  APPLICATIONS: '/applications',
  TERMSCONDITIONS: '/terms-of-use',
  PRIVACYPOLICY: '/privacy-statement',
  COOKIESPOLICY: '/cookies-policy',
};
